import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, List, Typography } from '@mui/material';
import NavItem from './NavItem';

/**
 * Component for rendering a group of navigation items.
 * 
 * This component takes a navigation group item as a prop and uses the Redux store to access
 * the current state of the navigation menu. It iterates over the children of the passed navigation
 * item and renders each child according to its type. Currently, it supports rendering of 'item' types
 * with the `NavItem` component and displays a placeholder message for 'collapse' types indicating 
 * that this feature is available only in a paid version. It also conditionally renders the group title
 * based on the drawer's open state from the Redux store.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {Object} props.item - The navigation group item to render, containing children items and a title.
 * @returns {React.Element} The `NavGroup` component as a React element.
 */
const NavGroup = ({ item }) => {
  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;

  const navCollapse = item.children?.map((menuItem) => {
    switch (menuItem.type) {
      case 'collapse':
        return (
          <Typography key={menuItem.id} variant="caption" color="error" sx={{ p: 2.5 }}>
            collapse - only available in paid version
          </Typography>
        );
      case 'item':
        return <NavItem key={menuItem.id} item={menuItem} level={1} />;
      default:
        return (
          <Typography key={menuItem.id} variant="h6" color="error" align="center">
            Fix - Group Collapse or Items
          </Typography>
        );
    }
  });

  return (
    <List
      subheader={
        item.title &&
        drawerOpen && (
          <Box sx={{ pl: 3, mb: 1.5 }}>
            <Typography variant="subtitle2" color="textSecondary">
              {item.title}
            </Typography>
            {/* only available in paid version */}
          </Box>
        )
      }
      sx={{ mb: drawerOpen ? 1.5 : 0, py: 0, zIndex: 0 }}
    >
      {navCollapse}
    </List>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object
};

export default NavGroup;

import Navigation from './Navigation';
import SimpleBar from 'components/third-party/SimpleBar';

/**
 * Component that renders the content of a drawer.
 *
 * This component uses the `SimpleBar` component for a custom scrollbar experience and
 * wraps the `Navigation` component to display the navigation items. The `SimpleBar` is
 * styled to ensure the navigation items are displayed in a flex column layout. This setup
 * is intended for use in a sidebar or navigation drawer where vertical space might be limited
 * and scrollable content is necessary.
 *
 * @returns {JSX.Element} The DrawerContent component wrapped in a SimpleBar for custom scrolling.
 */
const DrawerContent = () => (
  <SimpleBar
    sx={{
      '& .simplebar-content': {
        display: 'flex',
        flexDirection: 'column'
      }
    }}
  >
    <Navigation />
  </SimpleBar>
);

export default DrawerContent;

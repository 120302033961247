import { lazy } from 'react';
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import ProtectedRoute from './ProtectedRoute';

//Medicube internal staff
// render - homepage
const HomepageDefault = Loadable(lazy(() => import('pages/homepage')));

// render - existing-users
const ExistingUsersPage = Loadable(lazy(() => import('pages/existing-users')));

// render - new-users
const NewUsersPage = Loadable(lazy(() => import('pages/new-users')));

// render - compliance-management
const CompliancePage = Loadable(lazy(() => import('pages/compliance-management')));

// render - patient-engagement
const PatientPage = Loadable(lazy(() => import('pages/patient-engagement')));

// render - help & support
const HelpPage = Loadable(lazy(() => import('pages/help&support')));

/**
 * `AdminRoutes` defines the main routing structure for the application's admin interface, 
 * utilizing React Router. It employs lazy loading for efficient, on-demand loading of components,
 * enhancing the application's performance and user experience. Each route is wrapped with a 
 * `ProtectedRoute` component to ensure that certain UIs are accessible only to authenticated users.
 * The `Loadable` utility component is used to display a loading indicator while the lazy-loaded
 * components are being fetched.
 *
 * The structure includes routes for various sections of the admin interface such as the homepage,
 * existing users management, new users management, compliance management, patient engagement, and 
 * help & support pages. Each route specifies a path and the component to render, encapsulated within 
 * the `MainLayout` component that provides a consistent layout across the admin interface.
 *
 * @module AdminRoutes
 * @type {Object} - A configuration object for React Router to use, defining all admin interface routes.
 * @property {string} path - The base path for the routing structure, set to '/' for the admin interface.
 * @property {React.ReactElement} element - The layout component that wraps around the entire routing 
 * structure's child routes, providing a common layout for all admin pages.
 * @property {Array} children - An array of route objects, each defining a specific path and the component
 * to render for that route, all protected by `ProtectedRoute` to ensure authentication.
 */
const AdminRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    //Medicube internal staff
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <HomepageDefault />
        </ProtectedRoute>
      ),
    },
    {
      path: 'homepage_admin',
      element: (
        <ProtectedRoute>
          <HomepageDefault />
        </ProtectedRoute>
      ),
    },
    {
      path: 'existing_users',
      element: (
        <ProtectedRoute>
          <ExistingUsersPage />
        </ProtectedRoute>
      ),
    },
    {
      path: 'new_users',
      element: (
        <ProtectedRoute>
          <NewUsersPage />
        </ProtectedRoute>
      ),
    },
    {
      path: 'compliance_management',
      element: (
        <ProtectedRoute>
          <CompliancePage />
        </ProtectedRoute>
      ),
    },
    {
      path: 'patient_engagement',
      element: (
        <ProtectedRoute>
          <PatientPage />
        </ProtectedRoute>
      ),
    },

    {
      path: 'help&support',
      element: (
        <ProtectedRoute>
          <HelpPage />
        </ProtectedRoute>
      ),
    }
  ]
};

export default AdminRoutes;

import { useRoutes } from 'react-router-dom';
import AdminRoutes from './AdminRoutes';
import LoginRoutes from './LoginRoutes';

/**
 * Combines admin and login routes using React Router's `useRoutes` hook.
 * This function consolidates routing configurations for the entire application,
 * allowing for a unified and organized navigation structure. It includes routes
 * for both authenticated admin sections and unauthenticated login flows.
 *
 * @returns {React.ReactElement} The complete set of route elements for the application.
 */
export default function ThemeRoutes() {
  const routes = [AdminRoutes, LoginRoutes];

  return useRoutes(routes);
}

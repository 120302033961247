import React, { useEffect, useState, useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

/**
 * `ProtectedRoute` is a higher-order component designed to wrap around any route that requires user authentication.
 * It uses the `useAuth` hook to check the user's authentication status. While the authentication status is being
 * checked, it displays a loading message. If the user is authenticated, it renders the child components; otherwise,
 * it redirects the user to the login page.
 *
 * This component is crucial for protecting routes that should only be accessible to authenticated users, thereby
 * enhancing the application's security and user experience.
 *
 * @param {Object} props - Component props.
 * @param {React.ReactNode} props.children - Child components to be rendered if the user is authenticated.
 * @returns {React.ReactElement} - Either renders the child components, a loading message, or redirects to the login page.
 */
const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const [authStatus, setAuthStatus] = useState({ loading: true, authenticated: false });

  const checkAuthentication = useCallback(async () => {
    const isAuthenticatedResult = await isAuthenticated();
    if (authStatus.loading !== false || authStatus.authenticated !== isAuthenticatedResult) {
      setAuthStatus({ loading: false, authenticated: isAuthenticatedResult });
    }
  }, [isAuthenticated, authStatus]);

  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);

  if (authStatus.loading) {
    return <div>Loading...</div>;
  }

  if (!authStatus.authenticated) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;

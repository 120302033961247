import React from 'react';
import { Box, Typography } from '@mui/material';
import NavGroup from './NavGroup';
import menuItems from 'menu-items';

/**
 * A navigation component that dynamically renders groups of navigation items.
 * 
 * This component uses the `menuItems` function to retrieve navigation items and then
 * maps over these items to render them. Each item is expected to have a type, and based on
 * this type, the component decides how to render the item. Currently, only items of type
 * 'group' are specifically handled, and they are rendered using the `NavGroup` component.
 * Items of other types are rendered as a warning message.
 * 
 * @returns {React.Element} A React element representing the dynamic navigation structure.
 */
const Navigation = () => {
  const { items } = menuItems();

  const navGroups = items.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;

import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';
import DrawerHeaderStyled from './DrawerHeaderStyled';
import Logo from 'components/Logo';

/**
 * Component for rendering the header section of the navigation drawer.
 * 
 * It includes the application's logo and can be extended to include other elements.
 * The visibility and alignment of its content are controlled by the `open` prop to
 * match the drawer's state. This component is typically used at the top of the drawer
 * content, providing a consistent area for branding or navigation aids.
 *
 * @param {Object} props - Props for the component.
 * @param {boolean} props.open - Controls the styling and content alignment within the header.
 * @returns {JSX.Element} The DrawerHeader component.
 */
const DrawerHeader = ({ open }) => {
  const theme = useTheme();

  return (
    <DrawerHeaderStyled theme={theme} open={open}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Logo />
      </Stack>
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool
};

export default DrawerHeader;

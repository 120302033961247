import { useEffect, useState, useCallback } from 'react';

/**
 * Custom React hook for managing a WebSocket connection.
 * 
 * This hook initializes a WebSocket connection to the provided URL and 
 * provides methods to manually connect and disconnect the WebSocket. It also ensures 
 * that the WebSocket connection is properly closed when the component using this hook 
 * unmounts, preventing memory leaks and other potential issues.
 * 
 * @param {string} url - The URL to which the WebSocket connection will be established.
 * @returns {Object} An object containing the `connect` and `disconnect` methods to manage the WebSocket connection.
 */
const useWebSocketManager = (url) => {
    const [socket, setSocket] = useState(null);

    /**
     * Establishes a WebSocket connection if not already connected.
     * 
     * This function creates a new WebSocket instance using the provided URL
     * and updates the state with this new instance if there's no current connection.
     */
    const connect = useCallback(() => {
        if (socket === null) {
            const newSocket = new WebSocket(url);
            setSocket(newSocket);
        }
    }, [socket, url]);

    /**
     * Closes the current WebSocket connection if it exists.
     * 
     * This function closes the WebSocket connection and resets the state, indicating
     * that there's no active WebSocket connection.
     */
    const disconnect = useCallback(() => {
        if (socket !== null) {
            socket.close();
            setSocket(null);
        }
    }, [socket]);

    useEffect(() => {
        return () => {
            disconnect();
        };
    }, [disconnect]);

    return { connect, disconnect };
};

export default useWebSocketManager;

import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import { drawerWidth } from 'config';

/**
 * Styled AppBar component that adjusts according to the navigation drawer's state.
 *
 * It transitions its margin and width properties based on the `open` prop to align with the
 * expanded or collapsed drawer, ensuring a seamless layout transition.
 *
 * @param {Object} theme - MUI theme object for consistent styling.
 * @param {boolean} open - Indicates if the navigation drawer is open or closed.
 * @returns {JSX.Element} The styled AppBar component.
 */
const AppBarStyled = styled(AppBar, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

export default AppBarStyled;

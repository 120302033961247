import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

/**
 * A styled component that serves as the header of the drawer.
 * 
 * It applies different styles based on the `open` prop to adjust the layout of the header
 * content, such as alignment and padding. This component utilizes the theme's mixins for
 * toolbar height and spacing utilities to ensure consistency with the overall design.
 *
 * @param {Object} props - Props for the component.
 * @param {boolean} props.open - Determines the styling based on the drawer's state.
 * @param {Object} theme - The theme object provided by Material-UI's ThemeProvider.
 * @returns {JSX.Element} The styled Box component acting as the drawer's header.
 */
const DrawerHeaderStyled = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  ...theme.mixins.toolbar,
  display: 'flex',
  alignItems: 'center',
  justifyContent: open ? 'flex-start' : 'center',
  paddingLeft: theme.spacing(open ? 3 : 0)
}));

export default DrawerHeaderStyled;

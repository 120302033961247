import { lazy } from 'react';	
import Loadable from 'components/Loadable';	
import MinimalLayout from 'layout/MinimalLayout';	
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));	

/**
 * Defines the routing for login-related pages within the application, utilizing a minimal layout.
 * This configuration sets up the route for the login page, employing lazy loading to improve
 * the performance by loading the components only when needed. `Loadable` is used to wrap the
 * lazily loaded component, which provides a fallback loading state until the component is fully loaded.
 *
 * @module LoginRoutes
 * @type {Object} - Configuration object for login route, specifying the path, layout, and children routes.
 */
const LoginRoutes = {	
  path: '/',	
  element: <MinimalLayout />,	
  children: [
    {	
      path: 'login',	
      element: <AuthLogin />	
    }
  ]
};	

export default LoginRoutes;
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import Cookies from 'js-cookie';

const setCookiesFromQueryParams = (navigate) => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const token = urlParams.get('token');

    if (email && token) {
        const expires = new Date(new Date().getTime() + 60 * 60 * 1000); 
        Cookies.set('email', email, { expires: expires });
        Cookies.set('token', token, { expires: expires });

        navigate('/homepage_admin');
    }
};

const App = () => {
    const navigate = useNavigate();

    useEffect(() => {
        setCookiesFromQueryParams(navigate);
    }, [navigate]);

    return (
        <ThemeCustomization>
            <ScrollTop>
                <Routes />
            </ScrollTop>
        </ThemeCustomization>
    );
};

export default App;

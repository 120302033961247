import { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

/**
 * A custom React hook for handling authentication.
 * 
 * It provides functionality to log in and log out a user, check if the user is authenticated,
 * and stores the user's state. The hook uses axios for HTTP requests and js-cookie for managing cookies.
 * 
 * @returns {Object} An object containing the user state, login, logout, and isAuthenticated methods.
 */
function useAuth() {
    const [user, setUser] = useState(null);

    /**
     * Attempts to log the user in with the provided username and password.
     * 
     * On a successful login, it stores the user's email and token in the state and in cookies.
     * It also sets a cookie expiration based on the 'exp' value received from the server.
     * 
     * @async
     * @param {string} username - The username for login.
     * @param {string} password - The password for login.
     * @returns {Promise<Object>} The response data from the login request.
     * @throws Will throw an error if the login request fails.
     */
    const login = async (username, password) => {
        try {
            const params = new URLSearchParams();
            params.append('username', username);
            params.append('password', password);

            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/login/token`, params, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            const { email, token, exp } = response.data;

            setUser({ email, token });

            const expires = new Date(new Date().getTime() + exp * 1000);
            Cookies.set('token', token, { expires: expires });
            Cookies.set('email', email, { expires: expires });

            return response.data;

        } catch (error) {
            console.error(error.response.data);
            throw error;
        }
    };

    /**
     * Logs out the current user.
     * 
     * Clears the user's information from both the state and cookies.
     */
    const logout = () => {
        Cookies.remove('token');
        Cookies.remove('email');

        setUser(null);
    };

    /**
     * Checks if the user is currently authenticated.
     * 
     * Attempts to verify the token stored in cookies by making a request to the server.
     * If the token is valid, it implies the user is authenticated.
     * 
     * @async
     * @returns {Promise<boolean>} True if the user is authenticated, false otherwise.
     */
    const isAuthenticated = async () => {
        const token = Cookies.get('token');
        if (!token) {
            return false;
        }

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/login/verify-token`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response.status === 200;
        } catch (error) {
            return false;
        }
    };
    return {
        user,
        login,
        logout,
        isAuthenticated
    };
}
export default useAuth;


import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import { drawerWidth } from 'config';

/**
 * Style mixins for the opened state of the drawer.
 * 
 * @param {object} theme - The theme object provided by Material-UI's ThemeProvider.
 * @returns {object} Styles to be applied when the drawer is open.
 */
const openedMixin = (theme) => ({
  width: drawerWidth,
  borderRight: `1px solid ${theme.palette.divider}`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden',
  boxShadow: 'none'
});

/**
 * Style mixins for the closed state of the drawer.
 * 
 * @param {object} theme - The theme object provided by Material-UI's ThemeProvider.
 * @returns {object} Styles to be applied when the drawer is closed.
 */
const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: 0,
  borderRight: 'none',
  boxShadow: theme.customShadows.z1
});

/**
 * A Material-UI `Drawer` component styled to behave as a "mini" drawer.
 * 
 * This component adjusts its width and other styles based on the `open` prop, transitioning
 * between an opened and closed state with animations. It is designed to be used as a sidebar
 * navigation drawer that can be collapsed or expanded. The component conditionally applies styles
 * for both states using mixins and controls the forwarding of the `open` prop to the underlying
 * `Drawer` component.
 *
 * @param {object} props - The props to pass to the Drawer component.
 * @param {boolean} props.open - Controls whether the drawer is in its opened or closed state.
 * @returns {JSX.Element} The styled `Drawer` component.
 */
const MiniDrawerStyled = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

export default MiniDrawerStyled;

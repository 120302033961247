import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';
import { LogoutOutlined } from '@ant-design/icons';
import useAuth from 'hooks/useAuth';
import useWebSocketManager from 'hooks/useWebSocketManager';

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

/**
 * Renders header content, providing user interaction elements such as logout.
 *
 * Utilizes authentication and WebSocket management hooks for functionality, allowing
 * users to log out and disconnect from any active WebSockets before navigating to the
 * login page.
 *
 * @returns {JSX.Element} Box component containing the logout IconButton.
 */
const HeaderContent = () => {
  const { logout } = useAuth();
  const { disconnect } = useWebSocketManager();
  const navigate = useNavigate();

  const handleLogout = async () => {
    logout();
    disconnect();
    navigate('/login');
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
      <IconButton size="large" color="secondary" onClick={handleLogout}>
        <LogoutOutlined />
      </IconButton>
    </Box>
  );
};

export default HeaderContent;

import logo from 'assets/images/logo.png';

// ==============================|| LOGO PNG ||============================== //

const Logo = () => {
  return (
    <>
      <img src={logo} alt="MediCube" width="150" />
    </>
  );
};

export default Logo;
